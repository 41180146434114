import { createClientMessage } from 'react-chatbot-kit';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { BiSolidPurchaseTag } from "react-icons/bi";
import { auth, db } from '../../firebase';
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import './chatbot.css'


const ActionProvider = ({ createChatBotMessage, setState, children}) => {


  const [context, setContext] = useState('test')
  const [user, setUser] = useState(null);
  const [userCredits, setUserCredits] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([
    {company: "", quarter: "", query: ""}
  ]
  );


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        const querySnapshotNew = await getDocs(
          query(
            collection(db, "userdata"),
            where("email", "==", currentUser.email)
          )
        );

        if (!querySnapshotNew.empty) {
          const userData = querySnapshotNew.docs[0].data();
          setUserCredits(10);
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [userCredits]);


  const initialAction = () => {
    const cmessage = createClientMessage("Let's get started");
    updateState(cmessage)
    if(user){
    const message = createChatBotMessage("Sure, Let's move ahead. \n Enter a company");
    updateState(message, "quarter")
    }else{
      const message = createChatBotMessage("Please sign in");
      updateState(message, "null")
  }
  };

  const comp = (compName) => {
    const newData = [...data];
    newData[0].company = compName;
    setData(newData);
    console.log(data);
    const message = createChatBotMessage("Cool! Now enter the quarter. Your Quarter should be one of these - Q1/Q2/Q3/Q4")
    updateState(message, "query")
  }

  const quart = (quarter) => {
   
    const newData = [...data];
    newData[0].quarter = quarter;
    setData(newData);
    console.log(data);
    const message = createChatBotMessage("These are some of the FAQs for you. Meanwhile, you can enter any additional queries below", {
      widget:'PreBuilt',
    })
    updateState(message, "final");

  }
  
  const querypost = async (query) => {
    const newData = [...data];
    newData[0].query = query;
    setData(newData);
    const message = createChatBotMessage("Choose an option", {
      widget:'Get',
    })
    updateState(message,"final")
    // console.log(data)
  }

  // const finish = () => {
  //   const message = createChatBotMessage('Oops! you have run out of query limit :( ');
  //   updateState(message);
  // }

  const getAnswer = async () => {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-functions-key': 'tM6Yf4uzqbE2PolTAuOoW4OoUzIUNI9jExf4j2UVPZ0bAzFueZslxw==',
    };
    setIsLoading(true);

    const response = await axios.post('https://earning-call-index.azurewebsites.net/get_answer', data[0], { headers });

    setIsLoading(false);

    if (response.status === 200) {
      const message = createChatBotMessage(response.data, {
        widget:'review',
      });
      updateState(message, "final") ;
    } else {
      console.log("error");
    }

  };

  const getContext = async () => {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-functions-key': 'tM6Yf4uzqbE2PolTAuOoW4OoUzIUNI9jExf4j2UVPZ0bAzFueZslxw==',
    };
    setIsLoading(true);

    const response = await axios.post('https://earning-call-index.azurewebsites.net/get_context', data[0], { headers });

    setIsLoading(false);

    if (response.status === 200) {
      setContext(response.data)
      const message = createChatBotMessage(response.data, {
        widget:'review',
      });
      updateState(message, "final") ;
    } else {
      console.log("error");
    }
  };

   const updateState = (message, checker) => {
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      checker,
    })) 
  };




  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            initialAction,
            comp,
            quart,
            querypost,
            getAnswer,
            getContext,
            context,
          },


        }
        
        
        
        );
      })}

{isLoading && (
  <div class="shapes"></div>
)}

<div className='range' style={{position:'fixed'}}>
<div style={{marginTop:"10px"}}>
    <span>(     Available Credits:{" "}
                {userCredits ? userCredits : 0}{" "} )</span> <span className='credit'>&nbsp;<Link to="/creditsPricing" style={{color:"white"}}><BiSolidPurchaseTag style={{color:"white"}}/>Buy More</Link></span>
  
  </div>
  </div>
    </div>
  );
};

export default ActionProvider;

