import React from "react";
import { useState, useEffect } from "react";
import './Components.css'


export default function PreBuilt(props) {

  const ccName = props.state.dataa.ccName;
  const ccQuarter = props.state.dataa.ccQuarter;
  const string = ccName + "-" + ccQuarter;

  const [data, setData] = useState([]);

  const url =
    "https://cors.iamnd.eu.org/?url=https://storage.googleapis.com/stocks-earnings-calls/qna.json";

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((sdata) => {
        setData(sdata);
      })
      .catch((error) => console.error(error));
  }, []);

  return (

    <div class="acc">

      {Object.entries(data)
        .filter(([key]) => key.startsWith(string))
        .map(([key, value], i) => (
			<details key={key}>
			  <summary>{key}</summary>
			  <p>{value}</p>
			</details>
      ))}
			  
		</div>
  );
}
