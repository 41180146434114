import { createChatBotMessage } from 'react-chatbot-kit';
import BotAvatar from '../BotAvatar/BotAvatar';
import UserAvatar from '../BotAvatar/UserAvatar';
import StartBtn from '../Components/StartBtn';
import Company from '../Components/Company';
import PreBuilt from '../Components/PreBuilt';
import Get from '../Components/Get';
import Review from '../Components/Review';


const config = {
    initialMessages: [createChatBotMessage(`Hey buddy! I'm here to help you with Earnings call for 2023!🤗`, {
      widget: 'startBtn',
    })
  ],
    botName: "Bot for Earnings Call",
    customComponents:{
      botAvatar: (props) => <BotAvatar/>,
      userAvatar: (props) => <UserAvatar/>
    },
    state: {
      dataa : {
      ccName: '',
      ccQuarter: '',
      }
    },
    widgets:[
      {
        widgetName: 'startBtn',
        widgetFunc: (props) => <StartBtn {...props} />,
  
      },
      {
        widgetName:'Company',
        widgetFunc: (props) => <Company {...props} />,
      },
     {
        widgetName: 'PreBuilt',
        widgetFunc:(props) => <PreBuilt {...props} />,
      },
      {
        widgetName: 'Get',
        widgetFunc:(props) => <Get {...props} />,
      },
      {
        widgetName: 'review',
        widgetFunc: (props) => <Review {...props} />,
      },
      
    ]
  
};

export default config;