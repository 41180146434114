import React from 'react';

const MessageParser = ({ children, actions,}) => {
  
  const { checker } = children.props.state;

  const parse = (message) => {
    if (checker === "quarter") {
      const capitalizedMessage =  message[0].toUpperCase() + message.substring(1, message.length).toLowerCase();
      actions.comp(capitalizedMessage);
      children.props.state.dataa.ccName=capitalizedMessage;
  }
  if(checker==="query"){
    const capitalizedMessage =  message[0].toUpperCase() + message.substring(1, message.length).toLowerCase();
    children.props.state.dataa.ccQuarter=capitalizedMessage;
      if(capitalizedMessage==="Q1"){
      actions.quart("Previous Quarter");
      }
      if(capitalizedMessage==="Q2"){
        actions.quart("Current Quarter");
      }
  }
  if(checker==="final"){
    actions.querypost(message);
  }
   
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;